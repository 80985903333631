import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export function NowAllowedError() {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      You are not allowed to access this page
    </Alert>
  );
}
