import React, { useEffect, useContext } from 'react';
import { hotjar } from 'react-hotjar';
import { UserContext } from './../UserContext';

const setUserAttributes = (user) => {
  const userId = `${user.getTenant()}-${user.getTenantLevelUserId()}`;
  const isDemoUser = user.isDemoUser();
  const sso = user.getSSO();
  const identityProvider = user.getIdentityProvider();
  const ssoCompany = user.getSSOCompany();
  const ssoAgencyId = user.getSSOAgencyId();
  const ssoSub = user.getSSOSub();

  const isSSO = sso === true || sso === 'true' ? true : false;

  window.hj('identify', userId, {
    tenant: user.getTenant(),
    firstName: user.getFirstName(),
    lastName: user.getLastName(),
    email: user.getEmail(),
    ...(isSSO && { demo_user: isDemoUser }),
    ...(isSSO && { sso }),
    ...(isSSO && { identity_provider: identityProvider }),
    ...(isSSO && { sso_company: ssoCompany }),
    ...(isSSO && { sso_agency_id: ssoAgencyId }),
    ...(isSSO && { sso_sub: ssoSub }),
  });
};
export function Hotjar(props) {
  const { user } = useContext(UserContext);
  useEffect(() => {
    const hotjarId = parseInt(process.env.REACT_APP_HOTJAR_ID);
    const hotjarSnippetVersion = parseInt(
      process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
    );
    if (hotjarId && hotjarSnippetVersion) {
      hotjar.initialize(hotjarId, hotjarSnippetVersion);
      setUserAttributes(user);
    }
  }, [user]);
  return <>{props.children}</>;
}
