import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Title } from '../Header/Title';
import { SettingsIcon } from '../Header/Icons';
import SettingTabs from './SettingTabs';
import Integration from './Integration';
import AdvancedSettings from './AdvancedSettings';
import NotificationSetting from './NotificationSettings';
import RecommendationSettings from './RecommendationSettings';
import { UserContext } from '../UserContext';
import { NowAllowedError } from './../UserManagement/NotAllowedMessage';

const useStyles = makeStyles((theme) => ({
  icon: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      height: 20,
      width: 20,
    },
  },
}));

const SettingIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.icon}>
      <SettingsIcon />
    </div>
  );
};

export function Settings() {
  const match = useRouteMatch();
  const { user } = useContext(UserContext);
  const isDemoUser = user.isDemoUser();

  return (
    <>
      <Title titleText="Settings" icon={SettingIcon} hideDivider={true} />
      {!isDemoUser && (
        <>
          <SettingTabs />
          <Switch>
            <Route path={`${match.url}/integration`}>
              <Integration />
            </Route>
            <Route path={`${match.url}/advance`}>
              <AdvancedSettings />
            </Route>
            <Route path={`${match.url}/notification`}>
              <NotificationSetting />
            </Route>
            <Route path={`${match.url}/recommendation`}>
              <RecommendationSettings />
            </Route>
            <Route exact path={`${match.url}/`}>
              <Redirect to={`${match.url}/integration`} />
            </Route>
          </Switch>
        </>
      )}
      {isDemoUser && <NowAllowedError />}
    </>
  );
}
